<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-08-27 17:01:13
 * @LastEditTime: 2019-11-15 16:39:20
 * @LastEditors: Please set LastEditors
 -->
<template>
  <div class="favorite-container">
    <page-top :title="$t('supplier_submenu.99DB4A@Setting')">
      <span class="fa fa-cog fa-2x"></span>
    </page-top>
    <el-card>
      <section class="flex-end">
        <KtButton
            :label="$t('public.DC9752@btn_add')"
            class="table-btn btn-gray"
            :perm="4573"
            @click="add"
        ></KtButton>
      </section>
      <el-table
          :data="tableData"
          :row-class-name="$tableRowClassName"
          v-loading="loading"
          :element-loading-text="$t('public.4ABAB1@loading')"
          style="width: 100%; margin-top: 20px"
          :header-cell-style="{ 'text-align': 'center' }"
          :cell-style="{ 'text-align': 'center' }"
      >
        <el-table-column
            prop="week"
            :label="$t('shop_cart.883AA6@send_date')"
        ></el-table-column>
        <el-table-column
            prop="area"
            :label="
            $t('supplier-setting-DistributionSet.EB504B@Distribution_area')
          "
        ></el-table-column>
        <el-table-column :label="$t('public.03EA09@operation')" width="180">
          <template slot-scope="scope">
            <KtButton
                :label="$t('public.B6D57F@btn_modify')"
                type="info"
                :perm="8337"
                @click="modify(scope.row)"
            ></KtButton>
            <KtButton
                :label="$t('public.1FBB5E@btn_delete')"
                type="danger"
                :perm="1858"
                @click="deleteUser(scope.row)"
            ></KtButton>
          </template>
        </el-table-column>
      </el-table>

      <section class="flex-end mt20">
        <el-pagination
            @size-change="handleSizeChange"
            :page-sizes="[5, 10, 15]"
            :page-size="pageSize"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
        ></el-pagination>
      </section>
    </el-card>
    <el-dialog
        :title="
        addModify == 0
          ? $t('public.DC9752@btn_add')
          : $t('public.B6D57F@btn_modify')
      "
        width="45%"
        :visible.sync="dialogVisible"
        label-width="200px"
    >
      <el-form
          :model="dialogForm"
          ref="form"
          :rules="rules"
          label-width="120px"
      >
        <el-form-item
            :label="$t('supplier-setting-DistributionSet.F1E87C@Select_date')"
            prop="weekArr"
        >
          <el-select
              v-model="dialogForm.weekArr"
              multiple
              filterable
              clearable
              :placeholder="$t('placeholder.F0DDC0@select')"
              @change="selectWeek"
          >
            <el-option
                v-for="item in weekOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item
            :label="$t('supplier-setting-DistributionSet.1DB5AF@Select_region')"
            prop="areaArr"
        >
          <el-select
              v-model="dialogForm.areaArr"
              filterable
              clearable
              multiple
              :placeholder="$t('placeholder.F0DDC0@select')"
          >
            <el-option
                v-for="item in areaOptions"
                :key="item.district_id"
                :label="item.district"
                :value="item.district_id"
            ></el-option>
          </el-select>
        </el-form-item>
        <!--    最低配送金額    -->
        <el-form-item
            :label="$t('restaurant_supplier_supplierList.5CA70A@min_order')">
          <el-input-number v-model="dialogForm.amount_min" :min="0" @change="checkAmountMin"></el-input-number>
        </el-form-item>
        <!--    免運金額    -->
        <el-form-item
            :label="$t('restaurant_supplier_supplierList.8FC08B@reduction_conditions')">
          <el-input-number v-model="dialogForm.full_slicing" :min="0" @change="checkFullSlicing"></el-input-number>
        </el-form-item>
        <!--    預設運費    -->
        <el-form-item
        :label="$t('restaurant_supplier_supplierList.2E31F9@fee')">
          <el-input-number v-model="dialogForm.deliver_fee" :min="0" @change="checkFee"></el-input-number>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer d-flex justify-content-center">
        <el-button size="small" type="primary" @click="comfirmDialog('form')">
          {{ $t("public.3B45A2@confirm") }}
        </el-button>
        <el-button size="small" @click="dialogVisible = false">
          {{ $t("public.836FD4@cancel") }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import pageTop from "../../components/PageTop/PageTop";
import KtButton from "../../components/ktButton/KtButton";
import {longStackSupport} from "q";

export default {
  data() {
    return {
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      dialogForm: {
        weekArr: [],
        areaArr: [],
        //預設運費
        deliver_fee:0,
        //最低配送金額
        amount_min:0,
        //免運金額
        full_slicing:0,
      },
      rules: {
        weekArr: {
          required: true,
          message: this.$t("aboutus.B003A6@This_is_required"),
          trigger: "change",
        },
        areaArr: {
          required: true,
          message: this.$t("aboutus.B003A6@This_is_required"),
          trigger: "change",
        },
      },
      addModify: 0,
      delivery_id: "",
      weekObj: {
        mon: "",
        tues: "",
        wed: "",
        thur: "",
        fri: "",
        sat: "",
        sun: "",
      },
      dialogVisible: false,
      weekOptions: [
        {
          value: 1,
          label: this.$t("aboutus.99A454@monday"),
        },
        {
          value: 2,
          label: this.$t("aboutus.6FC140@Tuesday"),
        },
        {
          value: 3,
          label: this.$t("aboutus.7FE14B@Wednesday"),
        },
        {
          value: 4,
          label: this.$t("aboutus.1C3563@Thursday"),
        },
        {
          value: 5,
          label: this.$t("aboutus.AA11D7@Friday"),
        },
        {
          value: 6,
          label: this.$t("aboutus.78EC72@Saturday"),
        },
        {
          value: 7,
          label: this.$t("aboutus.CA70D5@sunday"),
        },
      ],
      areaOptions: [],
      loading: false,
    };
  },
  components: {
    pageTop,
    KtButton,
  },
  created() {
    this.getTableData();
    this.getHKDistrictSelector();
  },
  methods: {
    checkFullSlicing(){
      if(!this.dialogForm.full_slicing){
        this.dialogForm.full_slicing = 0
        this.$forceUpdate()
      }
    },
    checkAmountMin(){
      if(!this.dialogForm.amount_min){
        this.dialogForm.amount_min = 0
        this.$forceUpdate()
      }
    },
    checkFee(){
      if(!this.dialogForm.fee) {
        this.dialogForm.fee = 0
        this.$forceUpdate()
      }
    },
    // 获取列表数据
    getTableData() {
      this.loading = true;
      this.$http
          .getDistributionArea({
            size: this.pageSize,
            page: this.currentPage,
          })
          .then((res) => {
            this.loading = false;
            this.tableData = res.data.list;
            this.total = res.data.sum;
          });
    },
    //获取香港行政区
    getHKDistrictSelector() {
      this.$http.HKDistrictSelector().then((res) => {
        this.areaOptions = res.data;
      });
    },
    // 弹框确认
    comfirmDialog(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.addModify) {
            let params = {
              delivery_id: this.delivery_id,
              ...this.weekObj,
              district_json: JSON.stringify(this.dialogForm.areaArr),
              deliver_fee:this.dialogForm.deliver_fee,
              amount_min:this.dialogForm.amount_min,
              full_slicing:this.dialogForm.full_slicing,
            };
            // 修改
            this.$http.updateDistributionArea(params).then((res) => {
              this.dialogVisible = false;
              this.getTableData();
            });
          } else {
            let params = {
              ...this.weekObj,
              district_json: JSON.stringify(this.dialogForm.areaArr),
              deliver_fee:this.dialogForm.deliver_fee,
              amount_min:this.dialogForm.amount_min,
              full_slicing:this.dialogForm.full_slicing,
            };
            // 新增
            this.$http.addDistributionArea(params).then((res) => {
              this.dialogVisible = false;
              this.getTableData();
            });
          }
        } else {
          return false;
        }
      });
    },

    // 修改时获取信息
    modify(row) {
      this.delivery_id = row.delivery_id;
      Object.keys(this.weekObj).forEach((key) => {
        this.weekObj[key] = 0;
      });
      if (this.$refs["form"] !== undefined) {
        this.$refs["form"].resetFields();
      }
      this.dialogForm.weekArr = [];
      this.dialogForm.areaArr = [];
      this.addModify = 1;
      this.$http
          .getDistributionAreaInfo({
            delivery_id: row.delivery_id,
          })
          .then((res) => {
            this.dialogVisible = true;
            this.dialogForm.areaArr = res.data.district_json;
            this.dialogForm.deliver_fee = res.data.deliver_fee
            this.dialogForm.amount_min = res.data.amount_min
            this.dialogForm.full_slicing = res.data.full_slicing
            Object.keys(res.data).forEach((key) => {
              if (key == "mon" && res.data[key]) {
                this.dialogForm.weekArr.push(1);
                this.weekObj.mon = 1;
              } else if (key == "tues" && res.data[key]) {
                this.dialogForm.weekArr.push(2);
                this.weekObj.tues = 1;
              } else if (key == "wed" && res.data[key]) {
                this.dialogForm.weekArr.push(3);
                this.weekObj.wed = 1;
              } else if (key == "thur" && res.data[key]) {
                this.dialogForm.weekArr.push(4);
                this.weekObj.thur = 1;
              } else if (key == "fri" && res.data[key]) {
                this.dialogForm.weekArr.push(5);
                this.weekObj.fri = 1;
              } else if (key == "sat" && res.data[key]) {
                this.dialogForm.weekArr.push(6);
                this.weekObj.sat = 1;
              } else if (key == "sun" && res.data[key]) {
                this.dialogForm.weekArr.push(7);
                this.weekObj.sun = 1;
              }
            });
          });
    },
    // 打开新增弹框
    add() {
      this.addModify = 0;
      this.dialogVisible = true;
      Object.keys(this.weekObj).forEach((key) => {
        this.weekObj[key] = 0;
      });
      if (this.$refs["form"] !== undefined) {
        this.$refs["form"].resetFields();
      }
      this.dialogForm.weekArr = [];
      this.dialogForm.areaArr = [];
    },
    // 刪除
    deleteUser(row) {
      this.$confirm(
          this.$t("shop-goods.2ECFD0@Confirm_delete"),
          this.$t("public.553159@tips"),
          {
            confirmButtonText: this.$t("public.3B45A2@confirm"),
            cancelButtonText: this.$t("public.836FD4@cancel"),
          }
      ).then(() => {
        this.$http
            .delDistributionArea({
              delivery_id: row.delivery_id,
            })
            .then((res) => {
              if (this.tableData.length == 1 && this.currentPage > 1) {
                this.currentPage -= 1;
              }
              this.getTableData();
            });
      });
    },
    // 星期选择器
    selectWeek(val) {
      Object.keys(this.weekObj).forEach((key) => {
        this.weekObj[key] = 0;
      });
      val.forEach((v, i) => {
        switch (v) {
          case 1:
            this.weekObj.mon = 1;
            break;
          case 2:
            this.weekObj.tues = 1;
            break;
          case 3:
            this.weekObj.wed = 1;
            break;
          case 4:
            this.weekObj.thur = 1;
            break;
          case 5:
            this.weekObj.fri = 1;
            break;
          case 6:
            this.weekObj.sat = 1;
            break;
          case 7:
            this.weekObj.sun = 1;
            break;
        }
      });
    },

    handleSizeChange(val) {
      this.pageSize = val;
      this.getTableData();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getTableData();
    },
  },
};
</script>
<style lang="scss" scoped>
.tabs {
  width: 170px;
  margin-bottom: -20px;

  > ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0;

    li {
      text-align: center;
      width: 40px;
      font-size: 14px;
      line-height: 50px;
      color: #333333;
      cursor: pointer;
      transition: all 0.3s;
      box-sizing: border-box;
      border-bottom: 2px solid transparent;
    }
  }
}

.active {
  font-weight: 400;
  // border-bottom: 2px solid #333333;
  border-color: #333333 !important;
}
</style>
